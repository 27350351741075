import axios, { AxiosError } from 'axios';

import { config } from 'config';
import { getLocalisedIsoCountry } from 'utils/localization';
import { Analytics } from 'apis/Analytics';
import { handleError } from 'utils/error';
import Tracking from './tracking';

const checkoutPageVisit = async (code: string) => {
  try {
    const ApiBase = axios.create({
      baseURL: config.BASE_URL,
      params: {
        api_token: config.API_TOKEN,
        p_locale: getLocalisedIsoCountry().toLowerCase(),
      },
    });
    const fbEventId: string =
      (await ApiBase.get(`fb-track/checkout/${code}`))?.data?.data
        ?.facebook_event?.code ?? '';

    Tracking.logEvent({
      event: 'checkoutVisit',
      client_code: code,
      fb_event_id: fbEventId,
    });
  } catch (error) {
    handleError(error as AxiosError);
  }
};

const paymentSuccessful = async (props: {
  code: string;
  orderId: string;
  data: {
    currencyCode: string;
    paymentMethod: string;
    transactionId: string;
    transactionTotal: number;
    transactionAffiliation: string;
    transactionProducts: {
      sku: string;
      name: string;
      category: string;
      price: string;
      quantity: number;
    }[];
  };
}) => {
  try {
    Tracking.logEvent({
      event: 'paymentSuccessful',
      ...props.data,
    });
  } catch (error) {
    try {
      Tracking.logEvent({
        event: 'paymentSuccessful',
        ...props.data,
      });
    } catch (error) {
      handleError(error as AxiosError);
    }
    handleError(error as AxiosError);
  }
};

export const Events = {
  checkoutPageVisit,
  paymentSuccessful,
};
